.until {
  color: #ff0000;
  padding-top: 1em;
}

.days {
  .form-check {
    display: inline-block;
    padding-right: 10px;
  }
}
